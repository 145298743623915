import React, { useState } from 'react';
import colors from '../../assets/styles/colors.js';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let emailData = {
            email,
        };

        // Handle forgot password logic here
    };

    const backgroundStyle = {
        background: 'linear-gradient(to bottom, #C9EBFA, #FFFFFF)',
    };

    const formStyle = {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    };

    const buttonStyle = {
        backgroundColor: colors.primary,
        borderRadius: '0.375rem',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
    };

    return (
        <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
            <div className="bg-cyan-400 p-8 rounded-lg shadow-md w-full sm:w-96" style={formStyle}>
                <h2 className="text-2xl font-semibold mb-4 text-center" style={{ color: colors.primary }}>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="hover:bg-cyan-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 mr-2"
                            style={buttonStyle}
                        >
                            Reset Password
                        </button>


                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
