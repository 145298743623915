import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { formatISODate } from '../../utils/date';
import {BsInfo, BsPencil, BsTrash} from 'react-icons/bs'
import Swal from 'sweetalert2';
import { deleteGallery } from '../../services/gallery';
import { useState } from 'react';
import { Modal, ModalBody } from '@material-tailwind/react';
export default function TableGallery({ data }) {

  const [selectedItem, setSelectedItem] = useState(null);
  const handleDetailClick = (item) => {
    setSelectedItem(item);
  };

  const handleModalClose = () => {
    setSelectedItem(null);
  };

    return (
        <Card>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center bg-teal-500 rounded-lg px-4 md:px-7 py-4 md:py-6 shadow-2xl -mt-10">
                <h2 className="text-white mb-4 text-xl md:text-2xl md:mr-4 md:mb-0">
                    Jumat Berkah
                </h2>
              
            </div>
            <CardBody className="overflow-x-auto">
  <div className="max-h-[75vh] overflow-y-auto">
  <table className="min-w-full bg-transparent border-collapse">
  <thead className="md:table-header-group">
    <tr className="text-teal-500">
      <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">No</th>
      <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Nama</th>
      <th className="w-1/5 md:w-2/5 py-2 md:px-3 text-center text-xs md:text-sm">Tanggal Lahir</th>
      <th className="w-1/5 py-2 md:px-2 text-center text-xs md:text-sm">Keperluan</th>
      <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Action</th>
    </tr>
  </thead>
  <tbody>
    {data?.map((item, index) => (
      <tr key={String(index)} className="border-b border-gray-200">
        <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {index + 1}
        </td>
        <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {item?.name}
        </td>
        <td className="w-1/5 md:w-2/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {new Date(item?.birthday).toLocaleDateString()}
        </td>
        <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {item?.user_choose === "HAJI" ? 'Haji / Umrah' : 'Santri' }
        </td>
        <td className="w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          <div className="flex items-center justify-center space-x-2">
          <button className="shadow-md rounded" onClick={() => handleDetailClick(item)}>
                  <BsInfo size={15} />
          </button>
            
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>


  </div>
</CardBody>
{selectedItem && (
        <Modal size="sm" active={true} toggler={() => handleModalClose()}>
          <ModalBody>
            <h2 className="text-2xl font-semibold mb-4">{selectedItem.name}</h2>
            <p className='m-2'>Tempat, Tanggal Lahir: {selectedItem?.birth_place}, {new Date(selectedItem?.birthday).toLocaleDateString()}</p>
            <p className='m-2'>Hp: {selectedItem?.phone}</p>
            <p className='m-2'>Alamat: {selectedItem?.address}</p>
            <p className='m-2'>Status Peserta: {selectedItem?.user_choose === "HAJI" ? 'Haji / Umrah' : 'Santri'}</p>
            <p className='m-2'>Riwayat Penyakit: {selectedItem?.history_of_illness}</p>
            <p className='m-2'>Keluhan: {selectedItem?.description}</p>
            <p className='m-2'>
              {
                selectedItem?.user_choose === "SANTRI" ? (
                  <>
                  Nama Pesantren: {selectedItem?.pesantren_name}
                  Alamat Pesantren: {selectedItem?.pesantren_address}
                  </>
                ):(
                  <>
                  Tanggal Keberangkatan Haji: {new Date(selectedItem?.date_hajj).toLocaleDateString()}
                  </>
                )
              }
            </p>
            {/* ... (informasi lainnya) */}
           
          </ModalBody>
        </Modal>
      )}

        </Card>







    );
}
