import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import { changePasswordDoctor } from '../services/userService';
import { swal } from '../utils/alert';

export default function ChangePasswordComponent({ auth }) {
  const [formData, setFormData] = useState({
    password: '',
    new_password: '',
    confirm_password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Implement logic to handle password change using formData
    console.log('Form Data:', formData);

    return await changePasswordDoctor(formData)
    .then(res => {
        console.log(res,'ress')
        if(res.statusCode === 200){
            swal.success('Password berhasil diubah')
            setFormData({
                password: '',
                new_password: '',
                confirm_password: '',
              });
            setTimeout(() => {
                // window.location.reload()
            }, 1500);
        }
    }).catch(err => {
        console.log(err,'err')
        if(err){
            swal.error(err?.response?.data?.error?.message)
        }
    })

    // Reset the form after submission (optional)
  
  };

  return (
    <>
      <hr className='bg-black-500 p-2' />
      <div className='container'>
        <Card>
      <h1 className='text-2xl'>Ganti Password</h1>
          <form onSubmit={handleSubmit} className='p-6'>
            <div className='mb-4'>
              <label htmlFor='password' className='block text-sm font-medium text-gray-600'>
                Password Lama
              </label>
              <input
                type='password'
                id='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                className='mt-1 p-2 w-full border rounded-md'
                required
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='new_password' className='block text-sm font-medium text-gray-600'>
                Password Baru
              </label>
              <input
                type='password'
                id='new_password'
                name='new_password'
                value={formData.new_password}
                onChange={handleChange}
                className='mt-1 p-2 w-full border rounded-md'
                required
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='confirm_password' className='block text-sm font-medium text-gray-600'>
                Konfirmasi Password Baru
              </label>
              <input
                type='password'
                id='confirm_password'
                name='confirm_password'
                value={formData.confirm_password}
                onChange={handleChange}
                className='mt-1 p-2 w-full border rounded-md'
                required
              />
            </div>

            <button type='submit' className='bg-teal-500 text-white p-2 rounded-md'>
              Ganti Password
            </button>
          </form>
        </Card>
      </div>
    </>
  );
}
