import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { formatDate, formatISODate } from '../../utils/date';
import {BsPencil, BsTrash} from 'react-icons/bs'
import Swal from 'sweetalert2';
import { deleteGallery } from '../../services/gallery';
export default function TableGallery({ data }) {
  const handleDeleteClick = async (uuid) => {
    const result = await Swal.fire({
      title: 'Apakah Anda ingin menghapus?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ya, hapus!',
    });

    if (result.isConfirmed) {
      // Panggil API delete di sini
      try {
        // Gantilah dengan pemanggilan API delete sesuai kebutuhan Anda
        await deleteGallery(uuid)
        .then(res => {
          Swal.fire('Berhasil!', 'Data telah dihapus.', 'success');
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        })

        // Tampilkan notifikasi sukses jika API delete berhasil
      } catch (error) {
        // Tampilkan notifikasi error jika terjadi kesalahan pada API delete
        Swal.fire('Error!', 'Terjadi kesalahan saat menghapus data.', 'error');
      }
    }
  };

    return (
        <Card>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center bg-teal-500 rounded-lg px-4 md:px-7 py-4 md:py-6 shadow-2xl -mt-10">
                <h2 className="text-white mb-4 text-xl md:text-2xl md:mr-4 md:mb-0">
                    Gallery
                </h2>
                <Link className="bg-orange-500 py-2 px-2 rounded" to={'/galleries/create'}>
                    <Icon color={'white'} name="add" size="2xl" />
                </Link>
            </div>
            <CardBody className="overflow-x-auto">
  <div className="max-h-[75vh] overflow-y-auto">
  <table className="min-w-full bg-transparent border-collapse">
  <thead className="md:table-header-group">
    <tr className="text-teal-500">
      <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Judul</th>
      <th className="w-1/5 md:w-2/5 py-2 md:px-3 text-center text-xs md:text-sm">Tanggal Dibuat</th>
      <th className="w-1/5 py-2 md:px-2 text-center text-xs md:text-sm">Image</th>
      <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Action</th>
    </tr>
  </thead>
  <tbody>
    {data?.map((item, index) => (
      <tr key={String(index)} className="border-b border-gray-200">
        <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {item?.name}
        </td>
        <td className="w-1/5 md:w-2/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {formatDate(item?.created)}
        </td>
        <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          {item?.url ? (
            <img
              alt="doctore-image"
              className="rounded-full w-20 h-20 object-cover mx-auto"
              src={item.url}
            />
          ) : (
            <div className="w-20 h-20 rounded-full border-2 border-white mx-auto">
              No Image
            </div>
          )}
        </td>
        <td className="w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
          <div className="flex items-center justify-center space-x-2">
            <Link to={'/galleries/edit/'+item?.uuid}><BsPencil className='mr-2' color='brown' size={20} /></Link>
            <a href='#'>
            <BsTrash style={{}} onClick={() => handleDeleteClick(item?.uuid)} color='red' size={20} />
            </a>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>


  </div>
</CardBody>

        </Card>







    );
}
