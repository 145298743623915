
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { getSettings } from '../services/setting';
import { useQuery } from 'react-query';


export default function Settings() {
    const { data: settings, isFetched: detailFetch, isSuccess: success, isLoading: detailIsloading, refetch: detailRefatch } = useQuery(
      ["getSetting"],
      () => getSettings(),
      {
        refetchOnWindowFocus: false,
        retry: 2,
      }
    );
  
    return (
      <>
        <div className="px-3 md:px-8 h-auto mt-24">
          <div className="container max-w-full">
            <div className="grid grid-cols-1 xl:grid-cols-1">
              <div className="xl:col-start-1 xl:col-end-5 px-4 mb-16">
                <Card>
                  <CardHeader color="purple" contentPosition="none">
                    <div className="w-full flex items-center justify-between">
                      <h2 className="text-white text-2xl">Settings</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                  {success &&
                    settings?.data?.map((setting) => {
                        // Filter UUID yang tidak ingin ditampilkan
                        // if (setting.uuid === 'a79c656b-30e5-47c6-bcba-2750a47f81ab') {
                        //     return null; // Jika UUID tidak ingin ditampilkan, return null
                        // }
                        // if (setting.uuid === 'a79c656b-30e5-47c6-bcba-2750a47f81ac') {
                        //   return null; // Jika UUID tidak ingin ditampilkan, return null
                        // }
                        // if (setting.uuid === 'a79c656b-30e5-47c6-bcba-2750a47f81a2') {
                        //   return null; // Jika UUID tidak ingin ditampilkan, return null
                        // }

                        // Jika UUID boleh ditampilkan, render elemen <a>
                        return (
                            <a href={`/settings/${setting?.uuid}`} className="flex items-center" key={setting.id}>
                                <div className='w-full mb-3 shadow p-3 flex items-center justify-between'>
                                    {setting?.name}
                                    <BsArrowRight className='ml-auto'/>
                                </div>
                            </a>
                        );
                    })
                }
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
