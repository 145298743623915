// App.js
import React, { useEffect, useState } from 'react';
import 'assets/styles/tailwind.css';
import 'assets/styles/styles.css';
import Sidebar from '../components/Sidebar';
import { Dashboard, Doctors, Login, NotFound, Register, Settings, Tables, ForgotPassword, DoctorUpdate, Schedule, MyProfile, Layanan, ListMyReservation, DetailReservasiDoctor } from './Pages'
import { Switch, Route, Redirect } from 'react-router-dom';
import { getToken, getStorage } from '../utils/auth';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Gallery from '../pages/Gallery';
import GalleryForm from '../pages/Gallery/InputGallery';
import EditGalleryForm from '../pages/Gallery/EditGallery';
import CreateLayanan from '../pages/Layanan/CreateLayanan';
import Article from '../pages/Article';
import SettingForm from '../pages/Setting/update';
import EditLayanan from '../pages/Layanan/EditLayanan';
import Friday from '../pages/Friday';
// Components


function Routes() {
    const [token, setToken] = useState(null)
    const isUser = getStorage()
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
    let receptionist = String(isUser?.role?.[0]).toLocaleLowerCase() === 'receptionist'


    useEffect(() => {
        const isAuthenticated = getToken()
        if (isAuthenticated && !token) {
            setToken(isAuthenticated)
            let timerInterval;
            Swal.fire({
                title: "Sedang memuat data..",
                html: "Akan selesai dalam <b></b> milliseconds.",
                timer: 1000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                        timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });
        }
        return () => { }
    }, [isLoggedIn])

    return (
        <div>
            {token ?
                <>
                    <Sidebar />
                    <div className="md:ml-64">
                        <Switch>
                            <Route exact path="/" component={Dashboard} />
                            <Route exact path="/doctors" component={Doctors} />
                            <Route exact path="/reservasi" component={ListMyReservation} />
                            <Route exact path="/tables" component={Tables} />
                            
                            <Route path="/profile" component={MyProfile} />
                            <Route path="/reservasi/detail/:uuid" component={DetailReservasiDoctor} />

                            {admin ?
                                <>
                                    <Route exact path="/doctors" component={Doctors} />
                                    <Route exact path="/layanan" component={Layanan} />
                                    <Route exact path="/galleries" component={Gallery} />
                                    <Route exact path="/article" component={Article} />
                                    <Route exact path="/settings" component={Settings} />
                                    <Route exact path="/friday" component={Friday} />
                                    <Route path="/layanan/create" component={CreateLayanan} />
                                    <Route path="/galleries/create" component={GalleryForm} />
                                    <Route path="/doctors/schedule/:id" component={Schedule} />
                                    <Route path="/doctors/update/:id" component={DoctorUpdate} />
                                    <Route path="/galleries/edit/:uuid" component={EditGalleryForm} />
                                    <Route path="/layanan/edit/:uuid" component={EditLayanan} />
                                    <Route path="/settings/:uuid" component={SettingForm} />
                                </>
                                : receptionist ? 
                                <>
                                <Route exact path="/reservasi" component={ListMyReservation} />
                                </>
                                : 
                                <>
                                <Route path="/schedule" component={Schedule} />
                                <Route exact path="/article" component={Article} />
                                </>

                            }

                            <Redirect from="*" to="/" />
                            <Route component={NotFound} />
                        </Switch>



                    </div>
                </>
                :
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route component={NotFound} /> {/* This is the catch-all route */}
                </Switch>
            }
        </div >
    );
}

export default Routes;