import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Team1 from 'assets/img/team-1-800x800.jpg';
import Icon from '@material-tailwind/react/Icon';
import { Button } from '@material-tailwind/react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';
import { deleteUser } from 'services/userService';

export default function TableDoctor({ data, handleAdd }) {

    const [itemSelected, setItemSelected] = useState(null);

    const buttonRef = useRef(null);
    const contentRef = useRef(null);



    // Event listener to handle clicks outside the button and content
    const handleClickOutside = (event) => {
        if (
            buttonRef.current &&
            !buttonRef.current.contains(event.target) &&
            contentRef.current &&
            !contentRef.current.contains(event.target)
        ) {
            setItemSelected(null);
        }
    };

    const handleDelete = async (uuid) => {
        Swal.fire({
          title: "Delete",
          text: "Kamu yakin ingin menghapus ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "green",
          cancelButtonColor: "#d33",
          confirmButtonText: "Hapus"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteUser(uuid)
            window.location.reload()
          }
        });
      }
    
    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleToggleDropdown = (item) => {
        if (itemSelected?.uuid === item?.uuid) {
            setItemSelected(null); // Tutup dropdown jika sudah terbuka
        } else {
            setItemSelected(item); // Buka dropdown
        }
    };


    return (
        <Card>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center bg-teal-500 rounded-lg px-4 md:px-7 py-4 md:py-6 shadow-2xl -mt-10">
                <h2 className="text-white mb-4 text-xl md:text-2xl md:mr-4 md:mb-0">
                    Users
                </h2>
                <Button onClick={handleAdd} className="bg-orange-500">
                    <Icon name="add" size="2xl" />
                </Button>
            </div>
            <CardBody className="overflow-x-auto">
  <div className="max-h-[75vh] overflow-y-auto">
    <table className="min-w-full bg-transparent border-collapse">
      <thead className="md:table-header-group">
        <tr className="text-teal-500">
          <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Profile</th>
          <th className="w-1/5 md:w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Name</th>
          <th className="w-1/5 py-2 md:px-2 text-center text-xs md:text-sm">Layanan</th>
          <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Role</th>
          <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={String(index)} className="border-b border-gray-200">
            <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
              <div className="w-20 h-20 rounded-full border-2 border-white mx-auto">
                <img
                  alt="doctore-image"
                  className="rounded-full w-16 h-16 object-cover"
                  src={item?.image || Team1}
                />
              </div>
            </td>
            <td className="w-1/5 md:w-2/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
              {item?.name}
            </td>
            <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
              {item?.layanan?.[0]?.name ?? '-'}
            </td>
            <td className="w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center text-green-500">
              {item?.role[0]}
            </td>
            <td className="w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
              <div className="relative inline-block text-left">
                <button
                  type="button"
                  onClick={() => handleToggleDropdown(item)}
                  className="inline-flex justify-center w-full md:w-auto rounded-md border border-gray-300 shadow-sm px-2 py-1 md:py-2 bg-white text-xs md:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500"
                  id={`table-actions-button-${index}`}
                >
                  Actions
                </button>

                {itemSelected?.uuid === item?.uuid && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby={`table-actions-button-${index}`}
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      <Link
                        to={`/doctors/update/${item?.uuid}`}
                        className="block px-2 py-1 md:py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Edit Profile
                      </Link>
                      <Link
                        to={`/doctors/schedule/${item?.uuid}`}
                        className="block px-2 py-1 md:py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Edit Jadwal
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.uuid)}
                        type="button"
                        className="block px-2 py-1 md:py-2 text-xs md:text-sm text-red-500 hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Hapus
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</CardBody>

        </Card>







    );
}
