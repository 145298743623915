import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { addLayanan } from 'services/layananService';
import { swal } from '../../utils/alert';

export default function CreateLayanan() {
  // State untuk menyimpan data form

  
  const [layananData, setLayananData] = useState({
    name: '',
    image: null,
    header_description: '',
    description: '',
    header_about: '',
    about: '',
  });

  // Handle perubahan nilai input
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Jika input adalah file (image), simpan file object
    const newValue = type === 'file' ? files[0] : value;

    setLayananData({
      ...layananData,
      [name]: newValue,
    });
  };

  // Handle submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Lakukan sesuatu dengan data layananData, misalnya kirim ke backend

    const data = new FormData()
    data.append('name', layananData.name)
    data.append('image', layananData.image)
    data.append('header_description', layananData.header_description)
    data.append('description', layananData.description)
    data.append('header_about', layananData.header_about)
    data.append('about', layananData.about)

    await addLayanan(data)
    .then(res => {
      if(res.statusCode === 201){
          swal.success('Data berhasil terbuat')
          setTimeout(() => {
            window.location.href = '/layanan'
          }, 2000);
      }else{
          swal.error(res?.response?.data?.message)
      }
  }).catch(err => {
      alert(err)
  })
  };

  const previewImage = layananData.image
  ? URL.createObjectURL(layananData.image)
  : null;
  return (
    <>
      <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full"></div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                <h2 className="text-white text-2xl mr-4">
                  {/* Added 'mr-4' for right margin */}
                  Buat Layanan
                </h2>
              </div>
              <CardBody>
                {/* Form */}
                <form onSubmit={handleSubmit}>
                  {/* Nama Layanan */}
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Nama Layanan
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={layananData.name}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                      required
                    />
                  </div>

                  {/* Deskripsi Header */}
                  <div className="mb-4">
                    <label htmlFor="header_about" className="block text-sm font-medium text-gray-700">
                      Judul Tentang
                    </label>
                    <input
                      type="text"
                      id="header_about"
                      name="header_about"
                      value={layananData.header_about}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                      required
                    />
                  </div>

                  {/* Deskripsi */}
                  <div className="mb-4">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Deskripsi Judul
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      value={layananData.about}
                      onChange={handleChange}
                      rows="4"
                      className="mt-1 p-2 w-full border rounded-md"
                      required
                    ></textarea>
                  </div>

                  {/* About Header */}
                  <div className="mb-4">
                    <label htmlFor="header_description" className="block text-sm font-medium text-gray-700">
                      Judul Penjelasan Layanan
                    </label>
                    <input
                      type="text"
                      id="header_description"
                      name="header_description"
                      value={layananData.header_description}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"                      
                    />
                  </div>

                  {/* About */}
                  <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Penjelasan Layanan
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={layananData.description}
                      onChange={handleChange}
                      rows="4"
                      className="mt-1 p-2 w-full border rounded-md"
                    ></textarea>
                  </div>

                  {/* Gambar Layanan */}
                  <div className="mb-4">
                    <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                      Gambar Layanan
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                      accept="image/*"
                      required
                    />
                  </div>
                  {/* Tombol Submit */}

                  {previewImage && (
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Preview Gambar
                      </label>
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="mt-2 w-full h-auto rounded-md"
                      />
                    </div>
                  )}
                  <button type="submit" className="bg-teal-500 text-white p-2 rounded-md">
                    Simpan Layanan
                  </button>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
