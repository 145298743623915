import { useDispatch, useSelector } from 'react-redux';
import { listUsers, registerDoctor } from '../../services/doctorService.js';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import TableDoctor from '../../components/TableDoctor.js';
import { swal } from '../../utils/alert.js';

export default function Doctors() {
    const dispatch = useDispatch()
    const doctors = useSelector(state => state.doctor.doctors)

    useEffect(() => {
        dispatch(listUsers());
        return () => { }
    }, [dispatch]);

    const handleCreateDoctor = () => {
        Swal.fire({
            title: 'Tambah Dokter',
            html: `
            <div style="display: flex; flex-direction: column;">
                <form id="registration-form" style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Nama Lengkap"  type="text" id="name" class="appearance-none bg-transparent mb-2 border-b border-teal-500 w-full text-gray-700  py-3 px-2 leading-tight focus:outline-none" required>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Username" type="text" id="username" class="appearance-none bg-transparent mb-2 border-b border-teal-500 w-full text-gray-700  py-3 px-2 leading-tight focus:outline-none" required  maxlength="12">
                    </div>

                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Email"  type="email" id="email" class="appearance-none bg-transparent mb-2 border-b border-teal-500 w-full text-gray-700  py-3 px-2 leading-tight focus:outline-none" required>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Password"  type="password" id="password" class="appearance-none bg-transparent mb-2 border-b border-teal-500 w-full text-gray-700  py-3 px-2 leading-tight focus:outline-none" required>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                       
                        <select id="role" class="appearance-none bg-transparent border-b border-teal-500 w-full text-gray-700 py-3 px-2 leading-tight focus:outline-none" required>
                            <option value="" disabled selected>Select a role</option>
                            <option value="Master_Admin">Admin</option>
                            <option value="Receptionist">Receptionist</option>
                            <option value="Doctor">Dokter</option>
                        </select>
                    </div>
                </form>
            </div>
              `,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            preConfirm: () => {
                const emailInput = document.getElementById('email');
                const passwordInput = document.getElementById('password');
                const usernameInput = document.getElementById('username');
                const nameInput = document.getElementById('name');
                const roleInput = document.getElementById('role');

                const email = emailInput.value;
                const password = passwordInput.value;
                const username = usernameInput.value;
                const name = nameInput.value;
                const role = roleInput.value

                // Add your validation logic here
                if (name.length < 6) {
                    Swal.showValidationMessage('Name must be at least 6 characters');
                } else if (username.length < 6) {
                    Swal.showValidationMessage('Username must be at least 6 characters');
                } else if (!isValidEmail(email)) {
                    Swal.showValidationMessage('Invalid email address');
                } else if (password.length < 6) {
                    Swal.showValidationMessage('Password must be at least 6 characters');
                }
                // Handle the form data and registration process here
                // You can make an API request to create the user

                return {
                    email,
                    password,
                    username,
                    name,
                    role
                };
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Handle the registration success or error here
                const formData = result?.value;
                let response = await registerDoctor(formData)

                if (response?.statusCode == 200) {
                    swal.success('Dokter berhasil ditambahkan.')
                    dispatch(listUsers())
                }
                else if (String(response?.message).includes('422')) swal.error('Nama atau Email sudah pernah digunakan.')
                else swal.error(response?.data?.error?.message ?? response?.message)
            }
        });
    }
    function isValidEmail(email) {
        // You can use a regular expression for basic email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }


    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableDoctor data={doctors} handleAdd={handleCreateDoctor} />
                    </div>
                </div>
            </div>
        </>
    );
}
