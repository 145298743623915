import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getListLayanan } from 'services/layananService';
import { updateDoctorByAdmin, getDoctor } from 'services/userService';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { swal } from 'utils/alert'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from 'stores/actions/userAction';
import { getStorage } from 'utils/auth';

export default function DoctorUpdate(props) {
    let uuidDoctor = props?.match?.params.id

    const dispatch = useDispatch()
    const profileData = useSelector(state => state.user.profile)
    const user = getStorage()

    const [selectedService, setSelectedService] = useState([]);
    const [count, setCount] = useState(0);
    const [role, setRole] = useState(null)
    const [tickets, setTickets] = useState([{ name: "", start_date: null, end_date: null, is_present: false }]);
    const [studies, setStudies] = useState([{ name: "", start_date: null, end_date: null, is_present: false }]);

    useEffect(() => {
        if (String(user?.role?.[0]).toLowerCase() === 'master_admin') setRole('admin')
        else if (String(user?.role?.[0]).toLowerCase() === 'doctor') setRole('doctor')
        else if (String(user?.role?.[0]).toLowerCase() === 'receptionist') setRole('receptionist')
        return () => { }
    }, [])

    const { data: myProfile, isSuccess, isLoading } = useQuery(
        "getDoctorProfile",
        () => getDoctor(uuidDoctor),
        {
            refetchOnWindowFocus: false,
            retry: 2,
            onSuccess: (res) => {
                const initialTickets = res?.data?.data?.experience?.map((ticketLink) => ({
                    name: ticketLink.name,
                    start_date: ticketLink.start_date?.split(' ')[0],
                    end_date: ticketLink.end_date?.split(' ')[0],
                    is_present: ticketLink.is_present
                }));
                if (initialTickets?.length) setTickets(initialTickets);


                const initialStudies = res?.data?.data?.studies?.map((ticketLink) => ({
                    name: ticketLink.name,
                    start_date: ticketLink.start_date?.split(' ')[0],
                    end_date: ticketLink.end_date?.split(' ')[0],
                    is_present: ticketLink.is_present
                }));
                if (initialStudies?.length) setStudies(initialStudies);


                const selectedServ = res?.data?.data?.layanan?.map(v => {
                    return {
                        label: v.name,
                        value: v.uuid
                    }
                })
                let newObject = res?.data?.data
                if (newObject) {
                    newObject.birth = res?.data?.data.birth?.split(' ')[0]
                    newObject.consul_time = res?.data?.data?.consultation_time
                    newObject.selectedService = selectedServ
                }

                setSelectedService(selectedServ)
                dispatch(setProfile(newObject))
                setCount(count + 1)
            }
        }
    );

    const removeTicket = (e, index) => {
        e.preventDefault()
        const updatedTickets = [...tickets];
        updatedTickets.splice(index, 1);
        setTickets(updatedTickets);
    };


    const removeStudy = (e, index) => {
        e.preventDefault()
        const updatedTickets = [...studies];
        updatedTickets.splice(index, 1);
        setStudies(updatedTickets);
    };

    const addTicket = (e) => {
        e.preventDefault()
        setTickets([...tickets, { name: "", start_date: null, end_date: null }]);
    };

    const addStudy = (e) => {
        e.preventDefault()
        setStudies([...studies, { name: "", start_date: null, end_date: null }]);
    };

    const { data: serviceList, isFetched: layananFetch, isSuccess: layananSuccess, isLoading: layananLoad } = useQuery(
        "getLayanan",
        () => getListLayanan(),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    );

    const handleServices = (selectedOptions) => {
        setSelectedService(selectedOptions);
        let newObject = profileData
        newObject.selectedService = selectedOptions
        newObject.service_uuids = selectedOptions
        dispatch(setProfile(newObject))
        setCount(count + 1)
    };

    const handleTicketChange = (index, field, value) => {
        const updatedTickets = [...tickets];
        updatedTickets[index][field] = value;
        setTickets(updatedTickets);
    };

    const handleStudyChange = (index, field, value) => {
        const updateStudies = [...studies];
        updateStudies[index][field] = value;
        setStudies(updateStudies);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedArtistUUIDs = selectedService.map(item => item.value);
        // Prepare the data to be sent
        let postData = {};

        if(profileData?.is_doctor){
            postData = {
                birth: profileData?.birth,
                name: profileData?.name,
                description: profileData?.description,
                service_uuids: selectedArtistUUIDs,
                gender: profileData?.gender,
                consul_time: profileData?.consul_time,
                doctor_uuid: uuidDoctor,

                experience: tickets.map(ticket => ({
                    name: ticket.name,
                    start_date: ticket.start_date,
                    end_date: ticket.end_date,
                    is_present: ticket.is_present
                })),
                studies: studies.map(ticket => ({
                    name: ticket.name,
                    start_date: ticket.start_date,
                    end_date: ticket.end_date,
                    is_present: ticket.is_present
                }))
            }
        }else{
            postData = {
                birth: profileData?.birth,
                name: profileData?.name,
                gender: profileData?.gender,
                doctor_uuid: uuidDoctor,
            }
        }

        if (navigator.onLine) {
            await updateDoctorByAdmin(postData)
                .then((response) => {
                    // Handle the response from the API if needed
                    if (response.data) showAlert(false, 'Data berhasil di ubah')
                    setTimeout(() => {
                window.location.href = '/doctors'
                    }, 1000);
                })
                .catch((error) => {
                    // Handle errors if the request fails
                    showAlert(true, String(error?.response?.data?.error?.message || error?.response?.data?.message || error?.message))
                });
        } else {
            showAlert(true, 'Mohon periksa koneksi anda')
        }
        // Make a POST request using axios
    };

    const showAlert = (error, message) => {
        if (error) return swal.error(message)
        else return swal.success(message)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        let newObject = profileData
        newObject[name] = value
        dispatch(setProfile(newObject))
        setCount(count + 1)
    };
    return (
        <>
            {
                isSuccess && (
                    <>
                        <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                            <div className="container mx-auto max-w-full">
                            </div>
                        </div>

                        <div className="px-3 md:px-8 h-auto -mt-24">
                            <div className="container mx-auto max-w-full">
                                <div className="grid grid-cols-1 px-4 mb-16">
                                    <Card>
                                        <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                                            <h2 className="text-white text-2xl mr-4"> {/* Added 'mr-4' for right margin */}
                                                Profile
                                            </h2>
                                        </div>
                                        <CardBody>
                                            <form className='flex flex-row flex-wrap' onSubmit={handleSubmit}>
                                                <div className="w-full flex items-center border-gray-500 py-2 mb-4">
                                                    <div className="w-1/2 pr-2">
                                                        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                                                            Nama Lengkap
                                                        </label>
                                                        <input
                                                            name="name"
                                                            placeholder="Masukan nama lengkap"
                                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="text"
                                                            aria-label="Full name"
                                                            value={profileData?.name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 pl-2">
                                                        <label htmlFor="birth" className="block text-gray-700 font-bold mb-2">
                                                            Tanggal Lahir
                                                        </label>
                                                        <input
                                                            name="birth"
                                                            placeholder="Tanggal Lahir"
                                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="date"
                                                            aria-label="Birth Date"
                                                            value={profileData?.birth}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="w-1/2 flex flex-col items-start border-gray-500 py-2 mb-4 pr-2">
                                                    <label htmlFor="gender" className="block text-gray-700 font-bold mb-2">
                                                        Jenis Kelamin
                                                    </label>
                                                    <select
                                                        required

                                                        name="gender"
                                                        value={profileData?.gender}
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">-- Pilih --</option>
                                                        <option value="Laki - Laki">Laki - Laki</option>
                                                        <option value="Perempuan">Perempuan</option>
                                                    </select>

                                                </div>

                                                {
                                                    profileData?.is_doctor === false ? (
                                                        <></>
                                                    ):(
                                                        <>
                                                         <div className="w-1/2 flex flex-col items-start border-gray-500 py-2 mb-4">
                                                    <label htmlFor="consul_time" className="block text-gray-700 font-bold mb-2">
                                                        Pilih durasi konsultasi dokter
                                                    </label>
                                                    <select
                                                        required
                                                        name="consul_time"
                                                        value={profileData?.consul_time}
                                                        onChange={handleChange}
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        aria-label="Waktu Lama Konsultasi"
                                                    >
                                                        <option value="">-- Pilih --</option>
                                                        <option value={10}>10 menit</option>
                                                        <option value={20}>20 menit</option>
                                                        <option value={30}>30 menit</option>
                                                        <option value={40}>40 menit</option>
                                                        <option value={50}>50 menit</option>
                                                        <option value={60}>60 menit</option>
                                                    </select>
                                                </div>

                                                <div className="w-full flex flex-col items-startpy-2 mb-4">
                                                    <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
                                                        Deskripsi Dokter:
                                                    </label>
                                                    <textarea
                                                        name="description"
                                                        placeholder="Deskripsi dokter"
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        style={{ height: '100px' }}
                                                        aria-label="Description"
                                                        value={profileData?.description ?? ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="w-full flex flex-col items-start py-2 mb-4">
                                                    <label htmlFor="layanan" className="block text-gray-700 font-bold mb-2">
                                                        Pilih Layanan:
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        name='layanan'
                                                        placeholder="-- Pilih --"
                                                        options={layananSuccess && serviceList?.data?.data.map(v => {
                                                            return {
                                                                label: v.name,
                                                                value: v.uuid
                                                            }
                                                        })}
                                                        onChange={handleServices}
                                                        value={selectedService?.length ? selectedService : profileData?.selectedService}
                                                        required
                                                    />

                                                </div>

                                                <div className="w-full flex flex-col items-start py-2 mb-4">
                                                    <label htmlFor="experience" className="block text-gray-700 font-bold mb-2">
                                                        Experience
                                                    </label>
                                                    {tickets.map((ticket, index) => (
                                                        <div key={index} className="flex flex-wrap mb-4">
                                                            <div className="w-full mb-2 md:mb-0">
                                                                <textarea
                                                                    rows="3"
                                                                    type="text"
                                                                    name={`name${index}`}
                                                                    placeholder="Name"
                                                                    value={ticket.name}
                                                                    onChange={(e) => handleTicketChange(index, "name", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 mr-2">
                                                                <input
                                                                    type="date"
                                                                    name={`start_date${index}`}
                                                                    value={ticket.start_date}
                                                                    onChange={(e) => handleTicketChange(index, "start_date", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 mr-auto">
                                                                <input
                                                                    type="date"
                                                                    name={`end_date${index}`}
                                                                    value={ticket.end_date}
                                                                    onChange={(e) => handleTicketChange(index, "end_date", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                    disabled={ticket.is_present}
                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    name={`is_present${index}`}
                                                                    checked={ticket.is_present}
                                                                    onChange={(e) => handleTicketChange(index, "is_present", e.target.checked)}
                                                                    className="mr-2 leading-tight"
                                                                />
                                                                <label htmlFor={`is_present${index}`} className="text-gray-700">
                                                                    Is Current
                                                                </label>
                                                            </div>
                                                            <div className="w-full md:w-1/5 ml-auto text-right" >
                                                                {index === tickets.length - 1 ? (
                                                                    <button
                                                                        className="w-1/1 bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                        onClick={addTicket}
                                                                    >
                                                                        +
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="w-2/2 bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-4 rounded focus:outline-none focus:shadow-outline text-center"
                                                                        onClick={(e) => removeTicket(e, index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="w-full flex flex-col items-start py-2 mb-4">
                                                    <label htmlFor="studies" className="block text-gray-700 font-bold mb-2">
                                                        Studies
                                                    </label>
                                                    {studies.map((study, index) => (
                                                        <div key={index} className="flex flex-wrap mb-4">
                                                            <div className="w-full mb-2 md:mb-0">
                                                                <textarea
                                                                    rows="3"
                                                                    type="text"
                                                                    name={`name${index}`}
                                                                    placeholder="Name"
                                                                    value={study.name}
                                                                    onChange={(e) => handleStudyChange(index, "name", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 mr-2">
                                                                <input
                                                                    type="date"
                                                                    name={`studyStartDate${index}`}
                                                                    value={study.start_date}
                                                                    onChange={(e) => handleStudyChange(index, "start_date", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 mr-auto">
                                                                <input
                                                                    type="date"
                                                                    name={`studyEndDate${index}`}
                                                                    value={study.end_date}
                                                                    onChange={(e) => handleStudyChange(index, "end_date", e.target.value)}
                                                                    className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                                    disabled={study.is_present}
                                                                />
                                                            </div>
                                                            <div className="w-1/2 md:w-1/5 mb-2 md:mb-0 flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    name={`studyIsPresent${index}`}
                                                                    checked={study.is_present}
                                                                    onChange={(e) => handleStudyChange(index, "is_present", e.target.checked)}
                                                                    className="mr-2 leading-tight"
                                                                />
                                                                <label htmlFor={`studyIsPresent${index}`} className="text-gray-700">
                                                                    Is Current
                                                                </label>
                                                            </div>
                                                            <div className="w-full md:w-1/5 mt-2 ml-auto text-right border-gray-300">
                                                                {index === studies.length - 1 ? (
                                                                    <button
                                                                        className="w-2/2 bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                        onClick={addStudy}
                                                                    >
                                                                        +
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="w-2/2 bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                                                        onClick={(e) => removeStudy(e, index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                        </>
                                                    )
                                                }
                                               
                                                <div className="w-full flex flex-col items-start py-2 mb-4">

                                                    <button type="submit" className="bg-blue-500 text-white font-bold py-2 px-4 rounded">
                                                        Simpan
                                                    </button>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card >
                                </div>
                            </div>
                        </div >
                    </>
                )
            }
        </>
    );
}
