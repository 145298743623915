import React, { useEffect, useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { detailLayanan, updateLayanan } from '../../services/layananService';
import { swal } from 'utils/alert';
import { useQuery } from 'react-query';

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const editorConfiguration = {
  toolbar: [
    'heading', '|',
    'bold', 'italic', '|',
    'bulletedList', 'numberedList', 'blockQuote', '|',
    'undo', 'redo'
  ],
};

export default function EditLayanan(props) {
    const uuid = props?.match?.params.uuid;
  
    const [layananData, setLayananData] = useState({
      name: '',
      image: null,
      header_description: '',
      description: '',
      header_about: '',
      about: '',
    });
    const [previewImage, setPreviewImage] = useState(null);
    const [isNewImageSelected, setIsNewImageSelected] = useState(false);
    const [addDescription, setAddDesc] = useState("");
    const handleChangeDesc = (event, editor) => {
      const data = editor.getData();
      setAddDesc(data);
     
    };
  
     

    
    const { data: detail, isFetched, isSuccess, isLoading, refetch } = useQuery(
        ['getGallery', uuid],
        () => detailLayanan(uuid),
        {
          refetchOnWindowFocus: false,
          retry: 2,
          onSuccess: (res) => {
            if(res){

                
                setLayananData({
                    name: res?.data?.name,
                    header_description: res?.data?.header_description,
                    description: res?.data?.description,
                    image: res?.data?.image,
                    header_about: res?.data?.header_about,
                    about: res?.data?.about,
                  });
      
                  setPreviewImage(res?.data?.image)
            }
        
            
          },
        }
      );
    
  
      const handleChange = (e) => {
        const { name, value, type, files } = e.target;
      
        setLayananData((prevData) => ({
          ...prevData,
          [name]: type === 'file' ? files[0] : value,
        }));
      
        if (type === 'file') {
          setIsNewImageSelected(true);
    
          const reader = new FileReader();
    
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
    
          if (files[0]) {
            reader.readAsDataURL(files[0]);
          }
        }
      };
      
    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append('name', layananData.name);
      
      data.append('header_description', layananData.header_description);
      data.append('description', layananData.description);
      data.append('header_about', layananData.header_about);
      data.append('about', layananData.about);
      if (isNewImageSelected) {
        data.append('image', layananData.image);
      }
      try {
        const response = await updateLayanan(uuid, data);
  
        if (response.statusCode === 200) {
          swal.success('Data berhasil terupdate');
          setTimeout(() => {
            window.location.href = '/layanan';
          }, 2000);
        } else {
         
          swal.error(response?.response?.data?.message);
        }
      } catch (error) {
       
        swal.error('Terjadi kesalahan saat menyimpan data');
      }
    };
  
  
    return (
      <>
        <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
          <div className="container mx-auto max-w-full"></div>
        </div>
  
        <div className="px-3 md:px-8 h-auto -mt-24">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <Card>
                <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                  <h2 className="text-white text-2xl mr-4">
                    Edit Layanan
                  </h2>
                </div>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Nama Layanan
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={layananData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
  
                  {/* Deskripsi Header */}
                  <div className="mb-4">
                    <label htmlFor="header_about" className="block text-sm font-medium text-gray-700">
                      Judul Tentang
                    </label>
                    <input
                      type="text"
                      id="header_about"
                      name="header_about"
                      value={layananData.header_about}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                      required
                    />
                  </div>

                  <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={layananData.about}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setLayananData((prevData) => ({
                            ...prevData,
                            about: data,
                          }));
                        }}
                      />
                  {/* Deskripsi */}
                  {/* <div className="mb-4">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Deskripsi Judul
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      value={layananData.about}
                      onChange={handleChange}
                      rows="4"
                      className="mt-1 p-2 w-full border rounded-md"
                      required
                    ></textarea>
                  </div> */}

                  {/* About Header */}
                  <div className="mb-4">
                    <label htmlFor="header_description" className="block text-sm font-medium text-gray-700">
                      Judul Penjelasan Layanan
                    </label>
                    <input
                      type="text"
                      id="header_description"
                      name="header_description"
                      value={layananData.header_description}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"                      
                    />
                  </div>

                  {/* About */}
                  <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Penjelasan Layanan
                    </label>
                    {/* <textarea
                      id="description"
                      name="description"
                      value={layananData.description}
                      onChange={handleChange}
                      rows="4"
                      className="mt-1 p-2 w-full border rounded-md"
                    ></textarea> */}
                    <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={layananData.description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setLayananData((prevData) => ({
                            ...prevData,
                            description: data,
                          }));
                        }}
                      />
                  </div>
                    <div className="mb-4">
                      <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                        Gambar Layanan
                      </label>
                      <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md"
                        accept="image/*"
                      />
                    </div>
  
                    {layananData.image && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                            Preview Gambar
                            </label>
                            <img
                            height={200}
                            width={300}
                            src={previewImage || layananData.image}
                            alt="Preview"
                            className="mt-2 rounded-md"
                            />
                        </div>
                        )}
  
                    <button type="submit" className="bg-teal-500 text-white p-2 rounded-md">
                      Simpan Layanan
                    </button>
                  </form>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
