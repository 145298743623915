import endpoint from "../utils/endpoint";
import api from "../utils/api";
import { getToken } from "../utils/auth";

export const getDetailSettings = async (uuid) => {
    try {
        const response = await api.get(endpoint.detailSetting + uuid);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getSettings = async () => {
    try {
        const response = await api.get(endpoint.listSetting);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateSetting = async (uuid, payload) => {
    try {
        const response = await api.put(endpoint.updateSetting + uuid, payload,{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};