// services/layananService.js

import api from '../utils/api';
import endpoint from '../utils/endpoint';
import { getToken } from '../utils/auth';

import { listLayananSuccess, listLayananFailed, addLayananSuccess, addLayananFailed } from '../stores/actions/layananAction';
import { swal } from 'utils/alert';

export const listLayanan = () => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.get(endpoint.list_layanan);
                const result = response?.data?.data;
                dispatch(listLayananSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(listLayananFailed(error));
            }
        } else {
            swal.offline('Periksa kembali koneksi internet anda!')
        }
    };
};

export const getListLayanan = async () => {
    try {
        return await api.get(endpoint.list_layanan)
    } catch (error) {
       
    }
}


export const addLayanan = async (payload) => {
    if (navigator.onLine) {
        try {
            const response = await api.post(endpoint.add_layanan, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response?.data;
        } catch (error) {
            return error
        }
    } else {
        swal.offline('Periksa kembali koneksi internet anda!')
    }
};

export const updateLayanan = async (uuid, data) => {
    try {
        const response = await api.put(`/${endpoint.update_layanan}${uuid}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const deleteLayanan = async (uuid) => {
    try {
        const response = await api.delete(`/${endpoint.delete_layanan}${uuid}`);
        return response?.data
    } catch (error) {
        throw error;
    }
};


export const detailLayanan = async (uuid) => {
    try {
        const response = await api.get(`/${endpoint.detail_layanan}${uuid}`);
        return response?.data
    } catch (error) {
        throw error;
    }
};