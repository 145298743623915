import React, { useState } from 'react';
import colors from '../../assets/styles/colors.js';
import { Link } from 'react-router-dom';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let userData = {
            email,
            password,
            username,
            name,
        };

        // Handle registration logic here
    };

    const backgroundStyle = {
        background: 'linear-gradient(to bottom, #C9EBFA, #FFFFFF)',
    };

    const formStyle = {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    };

    const buttonStyle = {
        backgroundColor: colors.primary,
        borderRadius: '0.375rem',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
    };

    return (
        <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
            <div className="bg-cyan-400 p-8 rounded-lg shadow-md w-full sm:w-96" style={formStyle}>
                <h2 className="text-2xl font-semibold mb-4 text-center" style={{ color: colors.primary }}>Register</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="username" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={handleUsernameChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="name" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleNameChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="hover:bg-cyan-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 mr-2"
                            style={buttonStyle}
                        >
                            Register
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <Link to="/login" className="hover:underline" style={{ color: colors.primary }}>Already have an account? Login</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;


