import api from "../utils/api";

export const getReservationByDoctor = async (limit, page) => {
    try {
        const response = await api.get(`/reservation/v1/doctor?limit=${limit}&page=${page}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getReservationAdmin = async (limit, page, status, search) => {
    try {
        const response = await api.get(`/reservation/v1?limit=${limit}&page=${page}${status && status !== 'ALL' ? '&status=' + status : ''}${search ? '&search=' + search : ''}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getReservationReceptionist = async (limit, page, status, search) => {
    try {
        const response = await api.get(`/reservation/v1/receptionist?limit=${limit}&page=${page}${status && status !== 'ALL' ? '&status=' + status : ''}${search ? '&search=' + search : ''}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailReservation = async (uuid, role) => {
    let doctor = role === 'doctor' ? 'doctor/' : ''
    try {
        const response = await api.get('/reservation/v1/' + doctor + uuid);
        return role === 'doctor' ? response?.data : response?.data?.data
    } catch (error) {
        throw error;
    }
};
export const getDetailReservationAdmin = async (uuid) => {
    console.log("🚀 ~ file: reservation.js:23 ~ getDetailReservationDoctor ~ uuid:", uuid)
    try {
        const response = await api.get(`/reservation/v1/doctor/${uuid} `);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getDashboardReservation = async (uuid) => {
    try {

        const response = await api.get(`/reservation/v1/dashboard`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getAdminDashboardReservation = async (uuid) => {
    try {

        const response = await api.get(`/reservation/v1/admin-dashboard`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getAdminChart = async (uuid) => {
    try {

        const response = await api.get(`/reservation/v1/chart`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const updateStatus = async (uuid, status) => {
    try {
        const response = await api.get(`/reservation/v1/update-status?uuid=${uuid}&status=${status}`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getLocStatistic = async () => {
    try {

        const response = await api.get(`/reservation/v1/location`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const updateSchedule = async (uuid, payload) => {
    try {

        const response = await api.put(`/reservation/v1/update-schedule/${uuid}`, payload);
        return response?.data
    } catch (error) {
        throw error;
    }
};