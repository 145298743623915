import { useQuery } from 'react-query';
import { getListArticle, getListArticleDoctor } from '../../services/article.js';
import TableArticle from './TableArticle.js';
import { getStorage } from '../../utils/auth.js';

export default function Article() {
    
    const user = getStorage()
    const { data: article, isFetched, isSuccess, isLoading, refetch, } = useQuery(
        ["getArticle"],
        () =>  String(user?.role?.[0]).toLowerCase() === 'master_admin' ? getListArticle() : String(user?.role?.[0]).toLowerCase() === 'doctor' ? getListArticleDoctor() : null,
        {
          refetchOnWindowFocus: false,
          retry: 2
        }
    );

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableArticle data={article?.data} />
                    </div>
                </div>
            </div>
        </>
    );
}
