// userReducer.js

import { userTypes } from '../actions/actionTypes';
import Cookies from 'js-cookie';

const initialState = {
    user: null,
    isLoggedIn: false,
    error: null,

    profile: null,
    docterUuid: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.REGISTER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true,
                error: null,
            };
        case userTypes.REGISTER_FAILED:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                error: action.error,
            };
        case userTypes.LOGIN_SUCCESS:
            let storage = { uuid: action.payload.uuid, name: action.payload.name, role: action.payload.role, token: action.payload.token }
            Cookies.set('user', JSON.stringify(storage), { expires: 7 }); // Set to expire in 7 days
            Cookies.set('token', JSON.stringify(action.payload.token), { expires: 7 }); // Set to expire in 7 days
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true,
                error: null,
            };
        case userTypes.LOGIN_FAILED:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                error: action.error,
            };
        case userTypes.LOGOUT_SUCCESS:
          
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                error: null,
            };
        case userTypes.SET_PROFILE:
            return { ...state, profile: action.payload }

        case userTypes.SET_DOCTER_UUID:
            return {
                ...state,
                docterUuid: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;  