import React, { useState } from 'react';
import { Card, CardBody } from '@material-tailwind/react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { BsArrowLeft } from 'react-icons/bs';
import { swal } from '../../utils/alert';
import { getDetailSettings, updateSetting} from '../../services/setting';
import { useQuery } from 'react-query';
import { FORMSETTING } from '../../utils/enum';

const SettingForm = (props) => {
    const uuid = props?.match?.params.uuid;

    const [settingData, setDetailSetting] = useState({
        name: '',
        url: '',
        text_first: '',
        text_second: '',
        url_sec: '',
        part_of: '',
        form: ''
      });
    const { data: detailSetting, isFetched, isSuccess, isLoading, refetch } = useQuery(
        ['getDetailSetting', uuid],
        () => getDetailSettings(uuid),
        {
          refetchOnWindowFocus: false,
          retry: 2,
          onSuccess: (res) => {
            setDetailSetting({
              name: res?.data?.name,
              url: res?.data?.url,
              text_first: res?.data?.text_first,
              text_second : res?.data?.text_second,
              url_sec: res?.data?.url_sec,
              part_of: res?.data?.part_of,
              form: res?.data?.form
            });

            setPreviewImage({
                url: res?.data?.url,
                url_sec: res?.data?.url_sec,
            })
          },
        }
      );
      const [previewImage, setPreviewImage] = useState({
        url: null,
        url_sec: null,
      });
    const [formData, setFormData] = useState({
        file: null,
        url: null,
        name: '',
        url_sec: null,
        text_first: '',
        text_second: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          // Create FormData for handling files
          const data = new FormData();
          data.append('name', settingData.name);
          data.append('part_of', settingData.part_of);

          if(formData.text_first || settingData.text_first){
              data.append('text_first', formData.text_first === '' ? settingData?.text_first : formData.text_first);
          }
    
          // Optional fields
          if (formData.text_second || settingData.text_second) {
            data.append('text_second', formData.text_second === '' ? settingData?.text_second : formData.text_second);
          }
    
          if (formData.url) {
            data.append('url', formData.url);
          }
    
          if (formData.url_sec) {
            data.append('url_sec', formData.url_sec);
          }
    
          // Make the API call using the formData
           return await updateSetting(uuid, data).then(res => {
            if(res.statusCode === 200){
                
                swal.success('Data berhasil diubah')
                setTimeout(() => {
                    // window.location.reload()
                }, 1000);
            }else{
                swal.error(res?.response?.data?.error?.message ?? res?.response?.data?.message)
            }

          }).catch(err => {
            alert(err)
          })
    
          // Handle success
          // You can perform any other actions like redirecting or updating state as needed.
    
        } catch (error) {
          // Handle error
          console.error('Error updating gallery:', error);
          swal.error('Error!', 'Failed to update gallery', 'error');
        }
      };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === 'url' || name === 'url_sec') {
      // Handle image preview
      const previewURL = files ? URL.createObjectURL(files[0]) : null;
  
      // Update the settingData and formData
      setDetailSetting((prevSetting) => ({
        ...prevSetting,
        [name]: previewURL,
      }));
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: files ? files[0] : value,
      }));
  
      // Update the previewImage based on the input name
      setPreviewImage((prevImage) => {
        const newImage = {
          ...prevImage,
          [name]: previewURL || prevImage[name],
        };
        return newImage;
      });
    } else {
      // For non-image inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  
  

  return (
    <>
      <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
        
      </div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                <h2 className="text-white text-2xl flex items-center">
                  <Link to={'/settings'} className="mr-4">
                    {' '}
                    <BsArrowLeft />{' '}
                  </Link>
                  Edit
                </h2>
              </div>
              <CardBody>
                <form onSubmit={handleSubmit}>
                <div className="w-full pr-2 mb-4">
                            
                    <input
                        id="name"
                        name="name"
                        placeholder="Judul Album"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        aria-label="Full name"
                        defaultValue={settingData.name}
                        readOnly
                        />
                </div>
                <div className="w-full pr-2 mb-4">
        <label htmlFor="part_of" className="text-sm text-gray-600">
          Part Of
        </label>
        <input
          id="part_of"
          name="part_of"
          placeholder="Part Of"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          defaultValue={settingData.part_of}
          readOnly
        />
      </div>
      {
        settingData.form === FORMSETTING.JUSTIMAGE && (
            <>
            <div className="mb-4">
            <label htmlFor="url" className="text-sm text-gray-600">
              File
            </label>
            <input
              type="file"
              id="url"
              name="url"
              onChange={handleChange}
              className="w-full mt-1 py-2 px-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          {previewImage.url && (
            <div className="mt-4">
            <p>Gambar Pertama:</p>
            <img src={previewImage.url} alt="File Preview" className="max-w-full h-auto" />
            </div>
            )}

  </>
        )
      }
      {
        settingData.form === FORMSETTING.IMAGEANDTEXT && (
            <>
            <div className="w-full pr-2 mb-4">
            <label htmlFor="text_first" className="text-sm text-gray-600">
              Detail Pertama
            </label>
            <textarea
              id="text_first"
              name="text_first"
              placeholder="Detail"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              style={{height: 200}}
              onChange={handleChange}
              defaultValue={settingData?.text_first}
              
            ></textarea>
          </div>

          <div className="mb-4">
                    <label htmlFor="url" className="text-sm text-gray-600">
                      File
                    </label>
                    <input
                      type="file"
                      id="url"
                      name="url"
                      onChange={handleChange}
                      className="w-full mt-1 py-2 px-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>

                  {previewImage.url && (
                    <div className="mt-4">
                    <p>Gambar Pertama:</p>
                    <img src={previewImage.url} alt="File Preview" className="max-w-full h-auto" />
                    </div>
                    )}

          </>
          
        )
      }

      {

        settingData.form === FORMSETTING.ALLFORM && (
            <>

<div className="w-full pr-2 mb-4">
            <label htmlFor="text_first" className="text-sm text-gray-600">
              Detail Pertama
            </label>
            <textarea
              id="text_first"
              name="text_first"
              placeholder="Detail"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              style={{height: 200}}
              onChange={handleChange}
              defaultValue={settingData?.text_first}
              
            ></textarea>
          </div>

          <div className="mb-4">
                    <label htmlFor="url" className="text-sm text-gray-600">
                      File
                    </label>
                    <input
                      type="file"
                      id="url"
                      name="url"
                      onChange={handleChange}
                      className="w-full mt-1 py-2 px-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>

                  {previewImage.url && (
                    <div className="mt-4">
                    <p>Gambar Pertama:</p>
                    <img src={previewImage.url} alt="File Preview" className="max-w-full h-auto" />
                    </div>
                    )}

             <div className="w-full pr-2 mb-4">
        <label htmlFor="text_second" className="text-sm text-gray-600">
          Detail Kedua
        </label>
        <textarea
          id="text_second"
          name="text_second"
          placeholder="Text Second"
          style={{height: 200}}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          onChange={handleChange}
          defaultValue={settingData?.text_second}
          
        ></textarea>
      </div>
                 

                  <div className="mb-4">
                    <label htmlFor="url_sec" className="text-sm text-gray-600">
                      File
                    </label>
                    <input
                      type="file"
                      id="url_sec"
                      name="url_sec"
                      onChange={handleChange}
                      className="w-full mt-1 py-2 px-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>

                 
                    {previewImage.url_sec && (
                        <div className="mt-4">
                        <p>Gambar Kedua:</p>
                        <img src={previewImage.url_sec} alt="URL Sec Preview" className="max-w-full h-auto" />
                        </div>
                    )}
            </>
        )

      }
   

     

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="w-full bg-teal-500 text-white p-3 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    >
                      Submit
                    </button>
                  </div>
                  </form>
                
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingForm;
