import { useQuery } from 'react-query';
import TableGallery from './TableGallery.js';
import { getListGallery } from 'services/gallery.js';

export default function Gallery() {
    
    const { data: myGallery, isFetched, isSuccess, isLoading, } = useQuery(
        ["getGallery"],
        () =>  getListGallery(),
        {
          refetchOnWindowFocus: false,
          retry: 2
        }
      );

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableGallery data={myGallery?.data}/>
                    </div>
                </div>
            </div>
        </>
    );
}
