import { Card, Input, Button, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { createArticle } from "../../services/article";
import { swal } from "../../utils/alert";

export function FormArticle() {

    const [formData, setFormData] = useState({
        articleName: '',
        description: '',
        articleImage: null,
        articleFile: null,
    });
    const [loading, setLoading] = useState(false)
    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.articleName);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('image', formData.articleImage);
        formDataToSend.append('file', formData.articleFile);

        return await createArticle(formDataToSend)
        .then(res => {
            console.log(res,'ress')
            if(res.statusCode === 201){
                swal.success('Data berhasil terbuat')
                setTimeout(() => {
                    window.location.href = '/article'
                }, 1000);
            }
        }).catch(err => {
            console.log(err)
        })


    };


    return (
        <Card color="transparent" shadow={false}>
            <h2 color="blue-gray">
                Buat Artikel
            </h2>
            <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit}>
                <div className="mb-1 flex flex-col gap-6 w-full">
                    <p variant="h6" color="blue-gray" className="">
                        Article Name
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter article name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="articleName" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Description
                    </p>
                    <Textarea
                        size="regular"
                        placeholder="Enter article description"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="description" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray">
                        Article Image
                    </p>
                    <Input
                        type="file"
                        size="lg"
                        accept=".jpg, .jpeg" // Accept only JPG and JPEG files
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleImage" // Add name attribute

                    />
                    <p variant="h6" color="blue-gray">
                        Article File (PDF)
                    </p>
                    <Input
                        type="file"
                        size="lg"
                        accept=".pdf"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleFile" // Add name attribute
                    />
                </div>
                <Button className="mt-6" fullWidth>
                    Add Article
                </Button>
            </form>
        </Card>
    );
}
