import StatusCard from 'components/StatusCard';
import { useDispatch, useSelector } from 'react-redux';
import { addLayanan, listLayanan } from '../services/layananService';
import { useEffect, useState } from 'react';
import TableLayanan from '../components/TableLayanan'
import { swal } from '../utils/alert';

export default function Layanan() {
    const dispatch = useDispatch()
    const data = useSelector(state => state.layanan.data)


    useEffect(() => {
        dispatch(listLayanan());
        return () => { }
    }, [dispatch]);

    const handleAdd = () => {
        openModal()
    }


    const [isModalOpen, setModalOpen] = useState(false);
    const [layananName, setLayananName] = useState('');
    const [layananImage, setLayananImage] = useState('');
    const [imagePreview, setImagePreview] = useState(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setLayananName('');
        setLayananImage('');
        setImagePreview(null);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLayananImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = async () => {
        console.log(layananImage, 'image')
        if(!layananImage){
            swal.error('Foto harus diisi')
        }else{
            const formData = new FormData()
            formData.append('name', layananName)
            formData.append('image', layananImage)
    
            await addLayanan(formData)
            .then(res => {
                if(res.statusCode === 201){
                    swal.success('Data berhasil terbuat')
                    dispatch(listLayanan());
                }else{
                    swal.error(res?.response?.data?.message)
                }
            }).catch(err => {
                console.log(err,'datata')
                
            })
        }
       
        // Lakukan sesuatu dengan layananName dan layananImage
        closeModal();
    };

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableLayanan data={data} />
                    </div>
                </div>
            </div>
          
        </>
    );
}
