import endpoint from "../utils/endpoint";
import api from "../utils/api";
import { getToken } from "../utils/auth";

export const createArticle = async (payload) => {
    try {
        const response = await api.post(endpoint.createArticle, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getListArticle = async () => {
    try {
        const response = await api.get(endpoint.listArticle, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getListArticleDoctor = async () => {
    try {
        const response = await api.get(endpoint.listArticle, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailArticle = async (uuid) => {
    try {
        const response = await api.get(endpoint.detailarticle + uuid, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateArticle = async (uuid, payload) => {
    try {
        const response = await api.put(endpoint.updatearticle + `${uuid}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteImageArticle = async (uuid, payload) => {
    try {
        const response = await api.delete(endpoint.deleteImagearticle + `/${uuid}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteArticle = async (uuid) => {
    try {
        const response = await api.delete(endpoint.deleteArticle + `${uuid}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};