// services/doctorServices.js

import api from '../utils/api';
import endpoint from '../utils/endpoint';
import { listDoctorsSuccess, listDoctorsFailed, registerDoctorSuccess, registerDoctorFailed, addDoctorScheduleFailed, addDoctorScheduleSuccess, updateDoctorFailed, updateDoctorSuccess, getDoctorScheduleFailed, getDoctorScheduleSuccess } from '../stores/actions/doctorAction';

export const listDoctors = () => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.get(endpoint.list_doctors);
                const result = response?.data?.data;
                dispatch(listDoctorsSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(listDoctorsFailed(error));
            }
        } else {
            alert('Periksa kembali koneksi internet anda!')
        }
    };
};

export const listUsers = () => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.get(endpoint.list_users);
                const result = response?.data?.data;
                dispatch(listDoctorsSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(listDoctorsFailed(error));
            }
        } else {
            alert('Periksa kembali koneksi internet anda!')
        }
    };
};

export const registerDoctor = async (raw) => {
    if (navigator.onLine) {
        try {
            const response = await api.post(endpoint.register_doctor, raw);
            const result = response?.data;
            return result
        } catch (error) {
            return error
        }
    } else {
        alert('Periksa kembali koneksi internet anda!')
    }
};

export const updateDoctor = (raw) => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.post(endpoint.update_doctor, raw);
                const result = response?.data;
                dispatch(updateDoctorSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(updateDoctorFailed(error));
            }
        } else {
            alert('Periksa kembali koneksi internet anda!')
        }
    };
};


export const createDoctorSchedule = (raw) => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.post(endpoint.create_doctor_schedule, raw);
                const result = response?.data;
                dispatch(addDoctorScheduleSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(addDoctorScheduleFailed(error));
            }
        } else {
            alert('Periksa kembali koneksi internet anda!')
        }
    };
};

export const getDoctorSchedule = (param) => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.get(endpoint.list_doctors + param);
                const result = response?.data;
                dispatch(getDoctorScheduleSuccess(result));
            } catch (error) {
                alert(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                dispatch(getDoctorScheduleFailed(error));
            }
        } else {
            alert('Periksa kembali koneksi internet anda!')
        }
    };
};

export const getMyLayanan = async (uuid) => {
    try {
        return await api.get(uuid ? `profile/v1/admin/doctor/services/${uuid}` : "profile/v1/doctor/services")
    } catch (error) {
       
    }
}
