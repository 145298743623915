import endpoint from "../utils/endpoint";
import api from "../utils/api";
import { getToken } from "../utils/auth";


export const getListFriday = async () => {
    try {
        const response = await api.get(endpoint.createFriday, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
