import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { updateGallery, deleteImageGallery, getDetailGallery } from '../../services/gallery';
import { swal } from '../../utils/alert';
import {BsPlus} from 'react-icons/bs'
import { getStorage } from '../../utils/auth';
import imageCompression from 'browser-image-compression';

const EditGalleryForm = (props) => {
  const uuid = props?.match?.params.uuid;

  const isUser = getStorage()
  let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
  const [loading, setLoading] = useState(false)
  const { data: detailGallery, isFetched, isSuccess, isLoading, refetch } = useQuery(
    ['getGallery', uuid],
    () => getDetailGallery(uuid),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onSuccess: (res) => {
        setGalleryData({
          name: res?.data?.name,
          description: res?.data?.description,
          images: res?.data?.photos.map((photo) => ({ uuid: photo.photo_uuid, url: photo.url, file: null, isNew: false })),
        });
      },
    }
  );

  const [galleryData, setGalleryData] = useState({
    name: '',
    description: '',
    images: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGalleryData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = async (e, index) => {
    const images = [...galleryData.images];
  
    // Compress the image before updating the state
    const compressedFile = await compressImage(e.target.files[0]);
  
    images[index] = {
      ...images[index],
      file: compressedFile,
      url: URL.createObjectURL(compressedFile),
    };
  
    setGalleryData((prevData) => ({ ...prevData, images }));
  };
  
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      return file; // Jika gagal, gunakan file asli
    }
  };

  const handleRemoveImage = (uuid, index) => {
    Swal.fire({
      title: "Hapus Foto",
      text: "Apakah anda yakin ingin manghapus foto ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Hapus"
    }).then(async (result) => {
      if (result?.isConfirmed) {
        // Hapus foto baru yang belum disimpan di server
        if (galleryData.images[index].isNew) {
          const images = [...galleryData.images];
          images.splice(index, 1);
          setGalleryData((prevData) => ({ ...prevData, images }));
        } else {
          // Hapus foto yang sudah disimpan di server
          try {
            await deleteImageGallery(uuid);
            const images = [...galleryData.images];
            images.splice(index, 1);
            setGalleryData((prevData) => ({ ...prevData, images }));
          } catch (error) {
            console.error(error);
            swal.error(error?.response?.data?.message ?? 'Terjadi kesalahan')
          }
        }
      }
    });
  };

  const handleAddImage = () => {
    setGalleryData((prevData) => ({ ...prevData, images: [...prevData.images, { uuid: '', url: '', file: null, isNew: true }] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(false)
    const formData = new FormData();

    formData.append('name', galleryData.name);
    formData.append('description', galleryData.description);

    galleryData.images.forEach((image, index) => {
      if (image.file) {
        formData.append('image', image.file);
      }
    });

    try {
      await updateGallery(uuid, formData).then(res => {
        if(res.statusCode === 200){
            swal.success('Data berhasil diubah')
            setTimeout(() => {
                refetch()
            }, 2000);
        }
        setLoading(false)
      })
    } catch (error) {
      swal.error(error?.response?.data?.message ?? 'Terjadi kesalahan')
      setLoading(false)
    }
  };

  return (
    <form className="container mx-2 max-w-fullmt-8" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={galleryData.name}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
          Description:
        </label>
        <textarea
          id="description"
          name="description"
          value={galleryData.description}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded"
        />
      </div>

      <div className="mb-4 flex flex-wrap">
  <label htmlFor="images" className="block text-gray-700 font-bold mb-2 w-full">
    Images:
  </label>
  {galleryData?.images?.map((image, index) => (
    <div key={index} className="mb-2 flex items-center">
      {image.isNew ? (
        <input
          type="file"
          onChange={(e) => handleImageChange(e, index)}
          className="mr-2"
        />
      ) : null}
      <button
        type="button"
        onClick={() => handleRemoveImage(image.uuid, index)}
        className="bg-red-500 ml-2 text-white px-2 py-1 rounded"
      >
        Remove
      </button>
      {image && (
        <img
          src={image.url}
          alt={`Preview-${index}`}
          className="ml-4 w-16 h-16 object-cover rounded"
        />
      )}
    </div>
  ))}
  <button
    type="button"
    style={{height: 30}}
    onClick={handleAddImage}
    className="bg-blue-500 text-white ml-4 rounded"
  >
    <BsPlus size={30}/>
  </button>
</div>

      <div>
        {
          loading ? (
<button disabled className="bg-green-500 text-white px-4 py-2 rounded">
          Loading ...
        </button>
          ): (
            
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
        Update
      </button>
          )
        }
        
      </div>
    </form>
  );
};

export default EditGalleryForm;
