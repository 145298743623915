
import { Card, CardBody } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDetailReservation, getReservationAdmin, getReservationByDoctor, getReservationReceptionist, updateSchedule, updateStatus } from '../../services/reservation';
import { formatDate, formatReservationDateRange } from '../../utils/date';
import { getStorage } from '../../utils/auth';
import Icon from '@material-tailwind/react/Icon';
import { swal } from '../../utils/alert';
import { useSelector } from 'react-redux';
import { BsClock, BsInfo } from 'react-icons/bs';

export default function ListMyReservation() {
  const isUser = getStorage()
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
  let receptionist = String(isUser?.role?.[0]).toLocaleLowerCase() === 'receptionist'
  const user = getStorage()

  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [detailUuid, setDetailUuid] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [role, setRole] = useState(null)
  const [keyword, setKeyword] = useState(null)
  const [status, setStatus] = useState('All')
  const [onSearch, setOnSearch] = useState(1)
  const [statusMap, setStatusMap] = useState({});
  const [startDate, setStartDate] = useState('');
  const [startHour, setStartHour] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endHour, setEndHour] = useState('');
  const [respon, setRespon] = useState({
    loading: false,
    success: false,
    message: '',
    error: false
  })

  useEffect(() => {
    if (String(user?.role?.[0]).toLowerCase() === 'master_admin') setRole('admin')
    else if (String(user?.role?.[0]).toLowerCase() === 'doctor') setRole('doctor')
    return () => { }
  }, [])

  const { data: myReservation, isFetched, isSuccess, isLoading, refetch, } = useQuery(
    ["getReservation", page, onSearch],
    () => String(user?.role?.[0]).toLowerCase() === 'master_admin' ? getReservationAdmin(limit, page, status?.toUpperCase(), keyword) : String(user?.role?.[0]).toLowerCase() === 'doctor' ? getReservationByDoctor(limit, page) : getReservationReceptionist(limit, page, status?.toUpperCase(), keyword) ,
    {
      refetchOnWindowFocus: false,
      retry: 2
    }
  );

  const { data: detailReservation, isFetched: detailFetch, isSuccess: detailSuccess, isLoading: detailIsloading, refetch: detailRefatch } = useQuery(
    ["getDetail", detailUuid],
    () => getDetailReservation(detailUuid, role),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!detailUuid,
    }
  );

  const modalClose = () => {
    setDetailUuid('')
    setShowModal(false)
  }

  const modalCloseEdit = () => {
    setDetailUuid('')
    setShowModalEdit(false)
  }


  const handleShowModal = (uuid) => {
    setShowModal(true)
    setDetailUuid(uuid); // Mengatur UUID ke dalam state detailUuid
  };


  const handleShowModalEdit = (uuid) => {
    setShowModalEdit(true)
    setDetailUuid(uuid); // Mengatur UUID ke dalam state detailUuid
  };

  

  const handleStatusChange = async (index, newStatus, uuid) => {
    // Menangani perubahan status pada dropdown
    // Di sini Anda dapat menambahkan logika penyimpanan status yang baru di backend atau state aplikasi

    await updateStatus(uuid, newStatus)
    .then(res => {
      if(res.statusCode === 200){
        swal.success('Data berhasil diubah')
      }
      refetch()
    }).catch(err => {
      swal.error('gagal dapat mengubah data')
    })
    setStatusMap((prevStatusMap) => {
      return {
        ...prevStatusMap,
        [index]: newStatus,
      };
    });

    
  };
  const [isActive, setIsActive] = useState(false);

  const handleSearch = () => {
    setOnSearch(onSearch + 1)
  }

  const getItemProps = (index) =>
  ({
    variant: page === index ? "filled" : "text",
    color: "gray",
    onClick: () => setPage(index),
    className: "rounded-full",
  });

  const next = () => {
    if (page == Math.ceil(parseFloat(myReservation?.total) / parseFloat(myReservation?.perPage))) return;
    setPage(page + 1);
  };

  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const handleSubmit = async () => {
    if(!startDate){
      alert('Form tidak boleh kosong')
    }else if(!startHour){
      alert('Form tidak boleh kosong')
    }else if(!endDate){
      alert('Form tidak boleh kosong')
    }else if(!endHour){
      alert('Form tidak boleh kosong')
    }else{
      const formattedStartDate = `${startDate} ${startHour}:00.000`;
      const formattedEndDate = `${endDate} ${endHour}:00.000`;
  
      let payload = {
        start: formattedStartDate,
        end: formattedEndDate
      }
      await updateSchedule(detailUuid, payload)
      .then(res => {
        if(res.statusCode === 200){
          swal.success('Data berhasil diubah')
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      }).catch(err => {
        swal.error(err?.response?.data?.error.message ?? 'terjadi kesalahan')
      })
      // Selanjutnya, simpan ke database (misalnya, dengan menggunakan API atau fungsi penyimpanan data)
      // ...
  
      // Tutup modal setelah submit
      modalCloseEdit();
    }
    // Lakukan konversi format tanggal dan waktu sebelum menyimpan ke database
  
  };



  return (
    <>
      <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
        </div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                <h2 className="text-white text-2xl w-{100} mr-4"> {/* Added 'mr-4' for right margin */}
                  Reservasi
                </h2>
              </div>


              <CardBody className="overflow-x-auto">
                <div className="flex flex-row w-full justify-end items-end mb-3" >
                  <div className="relative inline-block text-left h-10" >
                    <button
                      onClick={() => setIsActive(!isActive)}
                      className="flex justify-center items-center w-full h-10 text-xs md:text-sm rounded-l-md px-4 bg-teal-500 text-white focus:outline-none focus:border-blue-300"
                    >
                      <p className='mr-1'>{status}</p>
                      <svg class="-mr-2 h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </button>

                    {/* Dropdown Content */}
                    {isActive && (
                      <div className="origin-top-right absolute -right-9 md:-right-8 mt-2 w-24 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        {/* Dropdown Items */}
                        <button
                          className="w-full block py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            setIsActive(!isActive)
                            setStatus('All')
                          }}
                        >
                          All
                        </button>
                        <button
                          className="w-full block py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            setIsActive(!isActive)
                            setStatus('Pending')
                          }}
                        >
                          Pending
                        </button>
                        <button
                          className="w-full block py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            setIsActive(!isActive)
                            setStatus('Accept')
                          }}                          >
                          Accept
                        </button>
                      </div>
                    )}
                  </div>

                  <input
                    type="text"
                    placeholder="Search.."
                    className="p-2 border border-gray-300 focus:outline-none focus:border-teal-500 w-full md:w-80 h-10 text-sm"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button
                    onClick={handleSearch}
                    type="button"
                    className="px-4 py-2 bg-teal-600 text-white focus:outline-none bg-teal-500 hover:bg-teal-700 h-10 rounded-r text-xs md:text-sm"
                  >
                    Search

                  </button>
                </div>



                <div className="max-h-[75vh] overflow-y-auto">
                <table className="min-w-full bg-transparent border-collapse">
  <thead className="md:table-header-group">
    <tr className="text-teal-500 border-b border-teal-500 ">
      <th className="py-2 px-2 md:px-3 text-center text-xs md:text-sm">No. Reservation</th>
      <th className="py-2 px-2 md:px-3 text-center text-xs md:text-sm">Name</th>
      <th className="py-2 px-2 md:px-3 text-center text-xs md:text-sm">Jadwal Reservasi</th>
      <th className="py-2 px-2 md:px-3 text-center text-xs md:text-sm">Status</th>
      <th className="py-2 px-2 md:px-3 text-center text-xs md:text-sm">Action</th>
    </tr>
  </thead>
  <tbody>
    {myReservation?.data?.map((item, index) => {
      if (item?.status && item?.email) {
        return (
          <tr key={String(index)} className={`border-b border-gray-200 ${item?.by_request ? 'bg-orange-500' : ''}`}>
            <td className="border-b md:border-b-0 border-gray-200 font-light text-[9px] md:text-sm py-2 md:py-4 text-center">
              {item?.no_reservation}
            </td>
            <td className="border-b md:border-b-0 border-gray-200 font-light text-[10px] md:text-sm py-2 md:py-4 text-center">
              {item?.name}
            </td>
            <td className="border-b md:border-b-0 border-gray-200 font-light text-[10px] md:text-sm py-2 md:py-4 text-center">
              {formatReservationDateRange(item?.time_reservation_start, item?.time_reservation_end)}
            </td>
            <td className={`border-b md:border-b-0 border-gray-200 font-normal text-[9px] md:text-sm whitespace-nowrap py-2 md:py-4 text-center max-w-[100px] mx-auto relative`}>
  {admin || receptionist ? (
    <div className="inline-block relative w-36 mx-auto">
      <select
        className={`block w-full py-1 border border-gray-300 bg-white rounded-md shadow-sm appearance-none focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500 text-[9px] md:text-sm text-center ${
          String(item?.status).toLowerCase() === 'done' ? 'text-green-700' : 
          String(item?.status).toLowerCase() === 'pending' ? 'text-yellow-500' : 
          String(item?.status).toLowerCase() === 'waiting_verify_email' ? 'text-purple-700' : 
          String(item?.status).toLowerCase() === 'accept' ? 'text-blue-600' : 
          'text-red-500'
        }`}
        defaultValue={item?.status ?? 'Loading'}
        onChange={(e) => handleStatusChange(index, e.target.value, item?.uuid)}
      >
        <option value="DONE">DONE</option>
        <option value="ACCEPT">ACCEPT</option>
        <option value="PENDING">PENDING</option>
        <option value="CANCEL">CANCEL</option>
        <option disabled value="WAITING_VERIFY_EMAIL">WAITING_VERIFY_EMAIL</option>
        {/* Tambahkan opsi status lain sesuai kebutuhan Anda */}
      </select>
    </div>
  ) : (
    <div className={`text-center ${
      String(item?.status).toLowerCase() === 'done' ? 'text-green-700' : 
      String(item?.status).toLowerCase() === 'pending' ? 'text-yellow-500' : 
      String(item?.status).toLowerCase() === 'waiting_verify_email' ? 'text-purple-700' : 
      String(item?.status).toLowerCase() === 'accept' ? 'text-blue-600' : 
      'text-red-500'
    }`}>
      {item?.status}
    </div>
  )}
</td>
            <td className="border-b md:border-b-0 border-gray-200 font-light text-[10px] md:text-sm whitespace-nowrap py-2 md:py-4 text-center">
              <div className="relative inline-block text-center">
                {
                  admin || receptionist ? (
                    <>
                       <button
                  type="button"
                  onClick={() => handleShowModalEdit(item.uuid)}
                  className="inline-flex mr-2 justify-center w-full md:w-auto rounded border border-gray-300 shadow-sm px-2 md:px-3 py-1 md:py-1 bg-white text-[9px] md:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500"
                  id={`table-actions-button-${index}`}
                >
                  <BsClock size={20} />
                </button>
                    </>
                  ): (<></>)
                }
           
                <button
                  type="button"
                  onClick={() => handleShowModal(item.uuid)}
                  className="inline-flex justify-center w-full md:w-auto rounded border border-gray-300 shadow-sm px-2 md:px-3 py-1 md:py-1 bg-white text-[9px] md:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500"
                  id={`table-actions-button-${index}`}
                >
                  <BsInfo size={20}/>
                </button>
              </div>
            </td>
          </tr>
        );
      }
      return null; // Tambahkan ini agar tidak ada undefined di array
    })}
  </tbody>
</table>

                </div>
              </CardBody>


              {
                showModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center ">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                    <div className="container grid grid-cols-1 bg-white w-full mx-auto rounded shadow-lg z-50 overflow-y-auto">
                      {/* Konten Modal */}
                      <div className="modal-content py-4 text-left px-6 ">
                        {/* Tombol Tutup Modal */}
                        <div className="flex justify-end items-center">
                          <button
                            onClick={() => modalClose()}
                            className="text-gray-400 hover:text-gray-800 focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        <h3 className="text-2xl font-semibold mb-2">Detail</h3>
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10">
                          <div>
                            <p className='py-2'><strong>No. Reservation:</strong> {detailReservation?.no_reservation}</p>
                            <p className='py-2'><strong>Profile Name:</strong> {detailReservation?.name}</p>
                            <p className='py-2'><strong>Description:</strong> {detailReservation?.description}</p>
                            <p className='py-2'><strong>Email:</strong> {detailReservation?.email}</p>
                            <p className='py-2'><strong>Status:</strong> {detailReservation?.status}</p>
                          </div>
                          <div>
                            <p className='py-2'><strong>Jadwal Janji:</strong> {formatReservationDateRange(detailReservation?.time_reservation_start, detailReservation?.time_reservation_end)}</p>
                            <p className='py-2'><strong>Terbuat pada:</strong> {formatDate(detailReservation?.created_at)}</p>
                            <p className='py-2'><strong>Handphone:</strong> {detailReservation?.phone_number ?? '-'}</p>
                            <p className='py-2'><strong>Kota:</strong> {detailReservation?.loc_name ?? '-'}</p>
                            <p className='py-2'><strong>Alamat:</strong> {detailReservation?.address ?? '-'}</p>
                            {/* Add more fields as needed */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )

              }

              {
              showModalEdit && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                  <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                  <div className="container grid grid-cols-1 bg-white w-3/4 sm:w-96 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    {/* Konten Modal */}
                    <div className="modal-content py-4 text-left px-6 ">
                      {/* Tombol Tutup Modal */}
                      <div className="flex justify-end items-center">
                        <button
                          onClick={() => modalCloseEdit()}
                          className="text-gray-400 hover:text-gray-800 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <h3 className="text-2xl font-semibold mb-2">Detail</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Input Tanggal dan Jam Awal di Sebelah Kiri */}
              <div className="border-r pr-4">
                <label className="block text-gray-700">Tanggal Mulai:</label>
                <input
                  type="date"
                  className="border rounded w-full py-2 px-3 mt-1"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  required
                />
                <label className="block text-gray-700 mt-2">Jam mulai:</label>
                <input
                  type="time"
                  className="border rounded w-full py-2 px-3 mt-1"
                  onChange={(e) => setStartHour(e.target.value)}
                  value={startHour}
                  required
                />
              </div>

              {/* Input Tanggal dan Jam Akhir di Sebelah Kanan */}
              <div>
                <label className="block text-gray-700">Tanggal Akhir:</label>
                <input
                  type="date"
                  className="border rounded w-full py-2 px-3 mt-1"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  required
                />
                <label className="block text-gray-700 mt-2">Jam Akhir:</label>
                <input
                  type="time"
                  className="border rounded w-full py-2 px-3 mt-1"
                  onChange={(e) => setEndHour(e.target.value)}
                  value={endHour}
                  required
                />
              </div>
            </div>
                      <div className="mt-4">
                        <button
                          onClick={() => handleSubmit()}
                          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
              
              }
            </Card>
          </div>
        </div>
      </div>
      {Math.ceil(parseFloat(myReservation?.total) / parseFloat(myReservation?.perPage)) > 1 ?
        <div className="w-full flex flex-row items-center justify-center gap-4 absolute bottom-20 right-0 left-0">
          <button disabled={page === 1}
            type="button" className={`px-4 py-2 ${page === 1 ? 'bg-gray-500' : 'bg-teal-500'} text-white focus:outline-none hover:bg-teal-700 h-10 rounded-full text-xs md:text-sm shadow-lg active:shadow-none`}>
            <Icon name="arrow_left" size="2xl" />
          </button>

          <div className="flex items-center gap-2">
            {Array.from({ length: Math.ceil(parseFloat(myReservation?.total) / parseFloat(myReservation?.perPage)) }, (_, i) => {
              let idx = i + 1
              return (
                <button onClick={prev} type="button" {...getItemProps(idx)} className={`px-4 py-2 bg-teal-600 text-white focus:outline-none bg-gray-500 ${page === idx ? 'bg-teal-500' : ''} hover:bg-teal-700 h-10 rounded-full text-xs md:text-sm shadow-lg active:shadow-none`}>{idx}</button>
              )
            })}
          </div>

          <button disabled={page == Math.ceil(parseFloat(myReservation?.total) / parseFloat(myReservation?.perPage))} onClick={next}
            type="button" className={`px-4 py-2 ${page === Math.ceil(parseFloat(myReservation?.total) / parseFloat(myReservation?.perPage)) ? 'bg-gray-500' : 'bg-teal-500'} text-white focus:outline-none hover:bg-teal-700 h-10 rounded-full text-xs md:text-sm shadow-lg active:shadow-none`}>
            <Icon name="arrow_right" size="2xl" />
          </button>

        </div>
        : null
      }

    </>
  );
}
