import StatusCard from '../components/StatusCard';
import { getAdminChart, getAdminDashboardReservation, getDashboardReservation, getLocStatistic } from '../services/reservation';
import { useQuery } from 'react-query';
import { getStorage } from 'utils/auth';
import ChartLine from '../components/ChartLine';
import ChartBar from '../components/ChartBar';
import { useState } from 'react';
import ChartLineDay from '../components/ChartLineDay';
import { Card, CardBody, CardHeader } from '@material-tailwind/react';

export default function Dashboard() {

    const isUser = getStorage()
    let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
    const [monthChart, setMonthChart] = useState([])
    const [dayChart, setDayChart] = useState([])
    const [yearChart, setYearChart] = useState([])
    const { data: myReservation, isError, isFetched, isSuccess, isLoading } = useQuery(
        ["dashboard"],
        () => admin ? getAdminDashboardReservation() : getDashboardReservation(),
        {
    OnWindowFocus: false,
            retry: 2
        }
    )

    const { data: chartAdmin, isError: chartError, isFetched: chartFetch, isSuccess: chartSuccess, isLoading: chartLading } = useQuery(
        ["chart"],
        () => admin ? getAdminChart() : null,
        {
            refetchOnWindowFocus: false,
            retry: 2,
            onSuccess: (res) => {
                
                setMonthChart(res?.data?.monthlyData)
                setDayChart(res?.data?.week)
                setYearChart(res?.data?.yearlyData)
            }
        }
    )

    const { data: dataLocation, isError: errLoc, isSuccess: successLoc } = useQuery(
        ["getLoc"],
        () => admin ? getLocStatistic() : null,
        {
            refetchOnWindowFocus: false,
            retry: 2,
            onSuccess: (res) => {
                
                setMonthChart(res?.data?.monthlyData)
                setDayChart(res?.data?.week)
                setYearChart(res?.data?.yearlyData)
            }
        }
    )
    

    if (isError) {
        return 'Gagal mengambil data'
    }

    return (
        <>
            {/* <div className="bg-light-blue-500 px-3 md:px-8 h-40" /> */}
            <div className="px-3 md:px-8 mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mb-4">
                        <StatusCard
                            color="pink"
                            icon="trending_up"
                            title="Total Reservasi"
                            amount={myReservation?.data?.countAll?.count}
                            // percentage="3.48"
                            // percentageIcon="arrow_upward"
                            // percentageColor="green"
                            // date="Since last month"
                        />
                        <StatusCard
                            color="orange"
                            icon="groups"
                            title="Reservasi Di Proses"
                            amount={myReservation?.data?.countOngoing?.reservations}
                            // percentage="3.48"
                            // percentageIcon="arrow_downward"
                            // percentageColor="red"
                            // date="Since last week"
                        />
                        <StatusCard
                            color="purple"
                            icon="pause"
                            title="Reservasi Tertunda"
                            amount={myReservation?.data?.countPending?.count}
                            // percentage="1.10"
                            // percentageIcon="arrow_downward"
                            // percentageColor="orange"
                            // date="Since yesterday"
                        />
                        <StatusCard
                            color="green"
                            icon="paid"
                            title="Reservasi Berhasil"
                            amount={myReservation?.data?.countDone?.count}
                            // percentage="1.10"
                            // percentageIcon="arrow_downward"
                            // percentageColor="orange"
                            // date="Since yesterday"
                        />
                        {/* <StatusCard
                            color="blue"
                            icon="poll"
                            title="Performance"
                            amount="49,65%"
                            percentage="12"
                            percentageIcon="arrow_upward"
                            percentageColor="green"
                            date="Since last month"
                        /> */}
                    </div>
                </div>
            </div>
            <div className="px-3 md:px-8 mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 sm:grid-cols-1">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            {
                                monthChart && admin && (
                                    <ChartLine month={monthChart}/>
                                )
                            }
                            
                        </div>
                        {/* <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            {
                                dayChart.length > 0 && (
                                    <ChartLineDay day={dayChart}/>
                                )
                            }
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="px-3 md:px-8 mt-12">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 sm:grid-cols-1">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                        <Card>
            <CardHeader color="orange" contentPosition="left">
                <h6 className="uppercase text-gray-200 text-xs font-medium">
                    Statistik
                </h6>
                <h2 className="text-white text-2xl">Lokasi Pasien</h2>
            </CardHeader>
            <CardBody>
            <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Code
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Nama Kota
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Hasil
                </th>
              </tr>
            </thead>
            <tbody>
            {dataLocation?.data?.map((item, index) => (
                <tr key={String(index)}>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                   {item.id}
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    {item?.name}
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                  {item?.count}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
          </CardBody>
          </Card>
          </div>
          </div>
          </div>
          </div>

            {/* 
            <div className="px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <PageVisitsCard />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            <TrafficCard />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
